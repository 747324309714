<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        On earth, tin has 10 different stable isotopes. The heaviest,
        <chemical-isotope symbol="Sn" mass-number="124" />
        makes up
        <stemble-latex :content="'$' + '5.80\\%' + '$'" />
        of naturally occurring tin atoms.
      </p>

      <p class="mb-2">
        a) How many atoms of
        <chemical-isotope symbol="Sn" mass-number="124" />
        are present in
        <number-value :value="taskState.getValueBySymbol('sampleMass').content" unit="\text{g}" />
        of a natural sample of tin?
      </p>

      <calculation-input
        v-model="input1"
        class="mb-4"
        prepend-text="$^\text{124}\text{Sn:}$"
        append-text="$\text{atoms}$"
        show-helpful-hint
        helpful-hint-position="below"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Considering
        <chemical-isotope symbol="Sn" mass-number="124" />
        has an isotopic mass of
        <stemble-latex :content="'$' + tinMass + '$'" />
        , calculate the mass of this isotope in the sample.<br />
      </p>

      <calculation-input
        v-model="input2"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalIsotope from '../displayers/ChemicalIsotope';

export default {
  name: 'Question8',
  components: {
    ChemicalIsotope,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: ['input1', 'input2'],
      input1: null,
      input2: null,
    };
  },
  computed: {
    tinMass() {
      return `\\ce{{${'123.905'}}${' amu'}}`;
    },
  },
};
</script>
